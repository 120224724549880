import { html, css, LitElement } from 'lit-element';

import { FwNotification } from './fw-notification.js';
import { FwHeaderProfile } from './fw-header-profile.js';
import { FwNav } from './fw-nav.js';

export class FwHeader extends LitElement {
  
  static get properties() {
    return {
        base: { type: String },
        force_login: { type: Boolean } 
    };  
  }

  constructor() {
    super();
    this.base = "";
  }
  
  render() {

	return  html`
            <header class="site-header"> 
              <div class="menu-bar">
              <div class="menu-container"> 
                <span class="site-title"><a href="/fw">FuquaWorld</a></span> 
                ${this.base == "" ? html`<fw-nav></fw-nav>`: 
                        html`<fw-nav base="${this.base}"></fw-nav>`}
                ${this.base == "" ? html`<fw-header-profile></fw-header-profile>`: 
                        html`<fw-header-profile .force_login=${this.force_login} base="${this.base}"></fw-header-profile>`}
              </div>
              </div>
                    ${this.base == "" ? html`<fw-notification></fw-notification>`: 
                        html`<fw-notification base="${this.base}"></fw-notification>`}
            </header>
            `;

  }

  createRenderRoot() {
	return this;
  }
  
}
if (!customElements.get('fw-header')) customElements.define('fw-header', FwHeader);

